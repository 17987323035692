import { LogManager } from 'aurelia-framework';
import get from 'lodash.get';
import { FileService } from '../services/api/file';
import { Cache } from '../services/util/FileCache';

const cache = new Cache('dfp');
const log = LogManager.getLogger('dfp:files');

export interface LoadedFile {
    promise: Promise<string|void>;
    url: string|null;
}

export class ObjectWithFiles {
    constructor(private __fileService: FileService){}

    _blobs: Record<string, LoadedFile> = {};

    getFile(idProp: string, imageProp: string, force?: boolean) {
        const existing = this._blobs[imageProp];
        if (existing) {
            if(force){
                this.destroyBlob(existing);
            } else {
                return existing.promise;
            }
        }

        const id = get(this, idProp);
        const promise = !id ?
            // if ID is not defined, skip loading
            Promise.resolve() :

            // if ID is a url, resolve it immediately
            id.match(/^https?:\/\//) ?
                Promise.resolve(id) :
                cache.fetchWithCache({id, fileService: this.__fileService});

        const blob: LoadedFile = {
            promise,
            url: null,
        };

        blob.promise.then((url) => {
            if(url){
                blob.url = url;
            }
            return url;
        });

        this._blobs[imageProp] = blob;

        return promise;
    }

    replaceFile(idProp: string) {
        const id = get(this, idProp);
        cache.fetchWithCache({id, fileService: this.__fileService}, true);
    }

    destroy() {
        Object.entries(this._blobs).forEach(([key, blob]) => {
            this.destroyBlob(blob);
        });
    }

    destroyBlob(blob: LoadedFile){
        if (blob?.url?.match(/^blob:/)) {

            log.debug(`Revoking object url: ${blob.url}`);
            URL.revokeObjectURL(blob.url);
        }
    }
}
