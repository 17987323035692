import { User } from '@wsb_dev/datafi-shared/lib/types/Users';
import { ValidationRules } from 'aurelia-validation';
import { FileService } from '../services/api/file';
import { ObjectWithFiles } from './ObjectWithFile';

export interface ValidatedUser extends User { }
export class ValidatedUser extends ObjectWithFiles {
    constructor(props: Partial<User>, files: FileService) {
        super(files);
        props.roles = props.roles || [];
        Object.assign(this, props);
    }

    getProfile(force?: boolean){
        return this.getFile('profileUrl', 'profile', force);
    }

    toString(): string{
        return `${this.fullname ? this.fullname : this.username} (${this.id})`;
    }
}

ValidationRules
    .ensure('username').required()
    .ensure('fullname').required()
    .ensure('email').required()
    .on(ValidatedUser);
