import { Paginated, Service } from '@feathersjs/feathers';
import { LogManager } from 'aurelia-framework';
import PQueue, { Options } from 'p-queue/dist/index';

const log = LogManager.getLogger('dfp:parallelQuery');

/**
 * This function will query a service recursively until it reaches
 * the last page of results. It will then merge all the results
 * into one final result array.
 *
 * @param service Service to query
 * @param query Query parameters - see https://docs.feathersjs.com/api/databases/querying.html#equality
 * @param result result array - generally just set this to an empty array unless you already have other data you want to merge
 * @returns Promise with ALL the data,
 */
export async function parallelQuery<T>(service: Service<T>, query: Record<string, unknown>, opt?: Options<any, any>): Promise<PQueue> {
    query = {
        $limit: 50,
        $skip: 0,
        ...query,
    };
    // get a first page result to calculate a total
    const firstResult = await service.find({ query }) as Paginated<T>;

    const perPage = query.$limit as number;
    const total = firstResult.total;
    const pages = total / perPage + (total % perPage > 0 ? 1 : 0);

    const concurrency = navigator.hardwareConcurrency ?
        Math.round(navigator.hardwareConcurrency / 2) : 2;
    navigator.hardwareConcurrency || 2;
    const queue = new PQueue({
        concurrency,
        ...opt,
    });

    queue.add(() => Promise.resolve(firstResult.data));

    let $skip = query.$skip as number;

    for (let i = 1; i < pages; i++) {
        const nextQuery = {
            ...query,
            $skip,
        };

        queue.add(() => {
            log.debug('Fetching page $skip:' + query.$skip);
            return service.find({ query: nextQuery })
                .then((result: Paginated<T>) => result.data);
        });

        $skip += perPage as number;
    }

    return queue;
}
